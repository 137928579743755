import React from 'react';
import { graphql } from 'gatsby';
import {
  ItemsListIndexPage,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
} from '../../../components';
import pageHeroData from '../../../data/pages/components.yml';

import SectionHierarchy from './partials/_SectionHierarchy';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Modules Overview"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Modules"
        tierThree="Overview"
      />

      <SectionHierarchy data={data} linksToShow={['Card Group', 'Data Card']} />

      <Section title="Choosing a Visualization">
        <Paragraph>
          All types of data can display in various ways. Some are more common
          because they are easier to digest and understand. Choose others
          because they're faster to build and manipulate. Data sets vary between
          sports, type, context and more. Rather than firm recommendations, we
          propose a set of possible options.{' '}
        </Paragraph>

        <Paragraph>
          There are so many different ways to visualize data. What's most
          important is asking ourselves how do the parts of the data relate? Is
          the data based on a common element like time or category, or might it
          be a correlation or comparison?
        </Paragraph>

        <Paragraph>
          To simplify the data landscape, we've derived a decision tree that
          aims to help you tell a convincing story. This acts as a guide, not a
          rule so as always test with our users to see which they identify with
          most.
        </Paragraph>

        <div style={{ textAlign: 'center' }}>
          <img
            src={require('../../../imgs/diagrams/module-viz-decision-tree.png')}
            alt="Decision tree used for selecting a visualization."
          />
        </div>

        <ItemsListIndexPage
          data={data}
          tierTwo="modules"
          listType="visual"
          listLayout="3"
          namesToShow={[
            'Histogram',
            'Segmented Bar',
            'Bar List',
            'Number List',
          ]}
        />
      </Section>

      <Section title="Filtering and Manipulating Data">
        <Paragraph>
          Some data sets don't need to be displayed as a larger visualization.
          In some cases the data set acts as a filter to manipulate downstream
          data. To ensure our users to feel in control of their data, we must
          find simple ways to drill down to what matters most to them.
        </Paragraph>

        <ItemsListIndexPage
          data={data}
          tierTwo="modules"
          listType="visual"
          listLayout="3"
          namesToShow={['Toggle']}
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query modulesDesignOverviewQuery($tierOne: String = "Components") {
    ...indexList
  }
`;
